import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Feature, Permission, Role } from '../enums/rights.enum';

@Injectable({
  providedIn: 'root',
})
export class RightService {
  private static rightsByRole: Map<Role, Map<Feature, number>> | null;

  public static canSeeOwnerPropertyOccupancyHabits: Role[] = [
    Role.SuperAdmin,
    Role.MainOwner,
    Role.Owner,
    Role.RenovationOperator,
    Role.PropertyManager,
  ];

  public static canSeeTenantPropertyOccupancyHabits: Role[] = [
    Role.SuperAdmin,
    Role.Tenant,
    Role.MainTenant,
    Role.RenovationOperator,
  ];

  // TODO rights remove temporary fine grained rights list
  private static allowedTenantPropertyEditFormKeys: string[] = [
    'waterDeviceVolume',
    'cooktopType',
    'bulbType',
    'ovenType',
    'adultOccupants',
    'minorOccupants',
    'thermostat',
    'heatingUpkeep',
    'ventilationUpkeep',
    'airInletUpkeep',
    'naturalAiring',
    'usualHeatingInstructionsTemp',
    'usualHeatingInstructionsHours',
    'nightHeatingInstructionsTemp',
    'nightHeatingInstructionsHours',
    'absenceHeatingInstructionsTemp',
    'absenceHeatingInstructionsHours',
    'weeklyShowers',
    'weeklyBaths',
  ];

  private _rightsBaseUrl = `${environment.api.baseUrl}/rights`;

  constructor(private readonly http: HttpClient) {}

  public static isAllowed(role: Role | undefined, feature: Feature, permission: Permission): boolean {
    if (role === undefined || !RightService.rightsByRole) {
      return true;
    }

    const featureRights = RightService.rightsByRole.get(role);

    if (!featureRights) {
      return true;
    }

    const permissionByFeature = featureRights.get(feature);

    if (permissionByFeature === undefined) {
      return true;
    }

    return (permissionByFeature & permission) !== 0;
  }

  // TODO rights rework hardcoded tenant rights
  public static canEditProperty(role: Role | undefined, formKey: string): boolean {
    if (role === undefined || (role !== Role.MainTenant && role !== Role.Tenant)) {
      return true;
    }

    return RightService.allowedTenantPropertyEditFormKeys.includes(formKey);
  }

  public getRights(): Observable<Map<Role, Map<Feature, number>>> {
    return this.http.get<Map<Role, Map<Feature, number>>>(this._rightsBaseUrl, { withCredentials: true }).pipe(
      tap((rightsByRole) => {
        RightService.rightsByRole = this.serializeRightsByRole(rightsByRole);
      }),
    );
  }

  private serializeRightsByRole(rightsByRole: object): Map<Role, Map<Feature, number>> {
    const entries: any = Object.entries(rightsByRole);

    for (const role of entries) {
      role[1] = new Map(Object.entries(role[1]));
    }

    return new Map(entries);
  }
}
