import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, tap } from 'rxjs';
import { AccountService } from '../cil/settings/account/account.service';
import { PropertyCount, User } from '../shared/all.types';
import { Feature, Role } from '../shared/enums/rights.enum';
import { AuthResult, AuthService } from '../shared/services/auth.service';
import { RightService } from '../shared/services/right.service';
import { GetPropertyCountsSuccess } from './property.state';

export interface AuthStateModel {
  loggedUser: User | null;
  propertyCounts: PropertyCount[];
}

export class GetRightsByRole {
  static readonly type = 'AUTH/GET_RIGHTS';

  constructor() {}
}

export class AuthSuccess {
  static readonly type = 'AUTH/AUTH_SUCCESS';

  constructor(public authResult: AuthResult) {}
}

export class AuthError {
  static readonly type = 'AUTH/AUTH_ERROR';
}

export class Login {
  static readonly type = 'AUTH/LOGIN';

  constructor(public payload: { email: string; password: string }) {}
}

export class LoginSuccess {
  static readonly type = 'AUTH/LOGIN_SUCCESS';
}

export class Logout {
  static readonly type = 'AUTH/LOGOUT';
}

export class UpdateAccount {
  static readonly type = 'ACCOUNT/UPDATE_ACCOUNT';

  constructor(public payload: Partial<User>) {}
}

export class SaveAccountAvatar {
  static readonly type = 'ACCOUNT/SAVE_AVATAR';
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loggedUser: null,
    propertyCounts: [],
  },
})
@Injectable()
export class AuthState {
  @Selector()
  public static loggedUser(state: AuthStateModel): User | null {
    return state.loggedUser;
  }

  @Selector()
  public static propertyCounts(state: AuthStateModel): PropertyCount[] {
    return state.propertyCounts;
  }

  constructor(
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
    private readonly rightService: RightService,
  ) {}

  @Action(UpdateAccount)
  public updateAccount(ctx: StateContext<AuthStateModel>, action: UpdateAccount): Observable<User> {
    return this.accountService.updateSelf(action.payload).pipe(
      tap((result: User) => {
        ctx.patchState({
          loggedUser: result,
        });

        return of(result);
      }),
    );
  }

  @Action(SaveAccountAvatar)
  public saveAccountAvatar(ctx: StateContext<AuthStateModel>): Observable<User> {
    return this.accountService.saveAvatar().pipe(
      tap((result: User) => {
        ctx.patchState({
          loggedUser: result,
        });

        return of(result);
      }),
    );
  }

  @Action(GetRightsByRole)
  public getRightsByRole(): Observable<Map<Role, Map<Feature, number>>> {
    return this.rightService.getRights();
  }

  @Action(AuthSuccess)
  public authSuccess(ctx: StateContext<AuthStateModel>, action: AuthSuccess): void {
    ctx.patchState({
      loggedUser: action.authResult.user,
      propertyCounts: action.authResult.count,
    });
  }

  @Action(GetPropertyCountsSuccess)
  public getPropertyCountsSuccess(ctx: StateContext<AuthStateModel>, action: GetPropertyCountsSuccess): void {
    ctx.patchState({
      propertyCounts: action.propertyCounts,
    });
  }

  @Action(AuthError)
  public authError(ctx: StateContext<AuthStateModel>): void {
    ctx.setState({
      loggedUser: null,
      propertyCounts: [],
    });
  }

  @Action(Login)
  public login(ctx: StateContext<AuthStateModel>, action: Login): Observable<boolean> {
    return this.authService
      .login(action.payload.email, action.payload.password)
      .pipe(tap(() => ctx.dispatch(new LoginSuccess())));
  }

  @Action(Logout)
  public logout(ctx: StateContext<AuthStateModel>): Observable<boolean> {
    return this.authService.logout().pipe(
      tap(() => {
        ctx.setState({
          loggedUser: null,
          propertyCounts: [],
        });
      }),
    );
  }
}
