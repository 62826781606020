import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { User } from '../../../shared/all.types';

@Injectable()
export class AccountService {
  private _baseUrl = `${environment.api.baseUrl}/account`;

  constructor(private readonly http: HttpClient) {}

  public updateSelf(user: Partial<User>): Observable<User> {
    return this.http.put<User>(this._baseUrl, user, { withCredentials: true });
  }

  public uploadAvatar(formData: FormData): Observable<{ fileName: string; resized: boolean }> {
    const url = `${this._baseUrl}/upload-avatar`;

    return this.http.post<{ fileName: string; resized: boolean }>(url, formData, {
      withCredentials: true,
    });
  }

  public saveAvatar(): Observable<User> {
    const url = `${this._baseUrl}/save-avatar`;

    return this.http.post<User>(
      url,
      {},
      {
        withCredentials: true,
      },
    );
  }

  public changePassword(newPassword: string): Observable<boolean> {
    const url = `${this._baseUrl}/changePassword`;

    return this.http.put<boolean>(url, { password: newPassword }, { withCredentials: true });
  }
}
