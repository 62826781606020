export enum Permission {
  View = 1,
  Create = 2,
  Update = 4,
  Delete = 8,
  Download = 16,
  Upload = 32,
}

export enum Feature {
  Property = 'property',
  Energy = 'energy',
  Document = 'document',
  Work = 'work',
  Participants = 'participants',
  ParticipantUser = 'participant_user',
  ParticipantTenant = 'participant_tenant',
  ParticipantOwner = 'participant_owner',
  ParticipantOrg = 'participant_org',
}

export enum Role {
  Owner = 'owner',
  Tenant = 'tenant',
  SuperAdmin = 'superadmin',
  RenovationOperator = 'renovationoperator',
  PropertyManager = 'propertymanager',
  MainOwner = 'mainowner',
  MainTenant = 'maintenant',
}
