import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthError, AuthSuccess, GetRightsByRole } from '../../state/auth.state';
import { PropertyCount, User } from '../all.types';

export interface AuthResult {
  user: User;
  count: PropertyCount[];
}

@Injectable()
export class AuthService {
  private _baseUrl = `${environment.api.baseUrl}/auth`;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  /**
   * Called at login & everytime we initialize the app.
   * Used to refresh the loggedUser & the rights matrix.
   */
  public auth(login = false): Observable<AuthResult> {
    return this.http.get<AuthResult>(this._baseUrl, { withCredentials: true }).pipe(
      map((result) => {
        this.store.dispatch(new AuthSuccess(result));
        this.store.dispatch(new GetRightsByRole());

        if (login) {
          void this.router.navigate(['/app']);
        }

        return result;
      }),
      catchError(() => {
        this.store.dispatch(new AuthError());

        return EMPTY;
      }),
    );
  }

  public login(email: string, password: string): Observable<boolean> {
    const url = `${this._baseUrl}/login`;

    return this.http.post<boolean>(url, { email, password }, { withCredentials: true });
  }

  public logout(): Observable<boolean> {
    const url = `${this._baseUrl}/logout`;

    return this.http.get<boolean>(url, { withCredentials: true }).pipe(
      map(() => true),
      catchError(() => EMPTY),
    );
  }
}
